import {throwAlertMessage} from "./utils";

export function getBookmarksConfig() {
    const _config = window._config || {};

    let baseConfig = _config.bookmarksConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.skiTicketConfigUrls". Value of "_config.activityTicketDataUrls" was not set in _config', true);
    }

    return baseConfig;
}

export function getCartItemsConfig() {
    const _config = window._config || {};

    let baseConfig = _config.cartItemsConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.skiTicketConfigUrls". Value of "_config.activityTicketDataUrls" was not set in _config', true);
    }

    return baseConfig;
}

export function getPayPalConfig() {
    const _config = window._config || {};

    let baseConfig = _config.paypalConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.paypalConfigUrls". Value of "_config.paypalConfigUrls" was not set in _config', true);
    }

    return baseConfig;
}