import {onEnterViewPort, isElementInViewport} from './viewport-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {findAll, findIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-newsletter', function (element) {
        let loaderSrc = element.dataset.loaderSrc;
        let trackingSrc = element.dataset.trackingSrc;
        let scriptIsDefinde = false ;


        onEnterViewPort(element, function () {
                if(!scriptIsDefinde){
                    let laodingScript = document.createElement('script');
                    laodingScript.type = "text/javascript";
                    laodingScript.src = loaderSrc;

                    let trackingScript = document.createElement('script');
                    trackingScript.type = "text/javascript";
                    trackingScript.id = "js-newsletter-loader";
                    trackingScript.src = trackingSrc;

                    element.append(trackingScript);
                    element.append(laodingScript);
                    scriptIsDefinde=true;
                }
            }, { offset: 400 });
    });
}
