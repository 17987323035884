import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {
    findIn,
    closest,
    removeClass,
    findAllIn,
    find,
    on,
    off,
    getAttribute,
    addClass,
    hasClass,
    setAttribute
} from '@elements/dom-utils';

const selectors = {
    base: '.js-nav',
    toggler: '.js-nav__toggle-overlay',
    link: '.js-nav__toggle-overlay-link',
    overlay: '.js-nav__overlay',
    mainToggler: '.js-nav__main-toggle',
    mainOverlay: '.js-nav__main-overlay',
    goBack: '.js-nav__go-back',
    closeMobileNav: '.js-nav__close-mobile-nav',
    itemList: '.js-nav__item-list',
    item: '.js-nav__item',
    wrapper: '.js-nav__wrapper',
};

const isTouchDevice = () => {
    return window.matchMedia("(max-width:767px), (pointer: coarse)").matches
}

const isMobile = matchMedia('(max-width: 767px)').matches || false;
export function init() {
    onFind(selectors.toggler, function (toggler) {
        let baseElement = closest(selectors.base, toggler);
        let overlays = findAllIn(selectors.overlay, baseElement);

        if(!isTouchDevice()) {
            //needed for "menu-aim" delay
            let timeout = 300;
            let timer;

            on('mouseenter', function (evt) {
                let item = evt.target;
                clearTimeout(timer);

                timer = setTimeout(function () {
                    removeClass('is-open', toggler);
                    let closestOverlay = closest(selectors.overlay, toggler);
                    overlays.map(x => removeClass('show-menu', x));
                    if (closestOverlay) {
                        addClass('show-menu', closestOverlay);
                    }
                    addClass('is-open', item);
                    addClass('show-menu', findIn(selectors.overlay, item));
                }, timeout);
            }, toggler);

            on('mouseleave', function () {
                clearTimeout(timer);

                timer = setTimeout(function () {
                    removeClass('is-open', toggler);
                    let closestOverlay = closest(selectors.overlay, toggler);
                    overlays.map(x => removeClass('show-menu', x));
                    if (closestOverlay) {
                        addClass('show-menu', closestOverlay);
                    }

                }, timeout);
            }, toggler)
        } else {
            let link = findIn(selectors.link, toggler);
            on('click', function (evt) {
                let item = evt.target;
                evt.preventDefault();

                let closestToggler = closest(selectors.toggler, item);
                removeClass('is-open', closestToggler);
                let closestOverlay = closest(selectors.overlay, toggler);

                function bodyClickHandler(evt) {
                    if (!closest('.main-navbar', evt.target)) {
                        evt.preventDefault();
                        let closestItem = find('.js-nav__toggle-overlay.is-open');
                        if(closestItem) {
                            let closestOverlay = findIn(selectors.overlay, closestItem);
                            removeClass('is-open', closestItem);
                            removeClass('show-menu', closestOverlay);
                            removeBodyClickHandler(bodyClickHandler);
                        }
                    }
                }

                overlays.map(x => removeClass('show-menu', x));
                if (closestOverlay) {
                    addClass('show-menu', closestOverlay);
                }
                addClass('is-open', closestToggler);
                addClass('show-menu', findIn(selectors.overlay, closestToggler));

                addBodyClickHandler(bodyClickHandler);

            }, link);
        }
    })

    onFind(selectors.mainOverlay, function (overlay) {
        if(isTouchDevice() && isMobile) {
            let messageHeight = find(selectors.wrapper).offsetHeight;
            overlay.style.height = 'calc(100svh - ' + messageHeight + 'px)';
            overlay.style.top = messageHeight + 'px';
            let outsideOverlay = findIn('.js-main-navbar__list-wrapper--after', overlay);
            let baseElement = find(selectors.base);
            let toggle = findIn(selectors.mainToggler, baseElement);
            on('click', function (evt) {
                removeClass('show-menu', overlay);
                removeClass('is-open', toggle);
                removeClass('nav-is-open', find('body'));
            }, outsideOverlay);
        }
    })

    onFind(selectors.item, function (toggler) {
        let baseElement = closest(selectors.itemList, toggler);

        if(!isTouchDevice()) {
            on('mouseenter', function () {
                    addClass('is-open', baseElement);
            }, baseElement);

            on('mouseleave', function () {
                removeClass('is-open', baseElement);
            }, baseElement)
        }
    })

    onFind(selectors.mainToggler, function(toggler) {
        if(isTouchDevice()) {
            on('click', function (evt) {
                let item = toggler;
                evt.preventDefault();

                if(!hasClass('is-open', item)) {
                    addClass('is-open', item);
                    addClass('show-menu', find(selectors.mainOverlay));
                    addClass('nav-is-open', find('body'));

                    window.dataLayer = window['dataLayer'] || [];

                    let dataLayerData = {
                        'event': 'genericGtmEvent',
                        'genericEventCategory': 'Navigation Mobile',
                        'genericEventAction': 'Hamburger-Menü-Icon',
                        'genericEventLabel': 'öffnen'
                    };

                    window.dataLayer.push(dataLayerData);


                } else {
                    removeClass('is-open', item);
                    removeClass('show-menu', find(selectors.mainOverlay));
                    removeClass('nav-is-open', find('body'));

                    window.dataLayer = window['dataLayer'] || [];

                    let dataLayerData = {
                        'event': 'genericGtmEvent',
                        'genericEventCategory': 'Navigation Mobile',
                        'genericEventAction': 'Hamburger-Menü-Icon',
                        'genericEventLabel': 'schließen'
                    };

                    window.dataLayer.push(dataLayerData);
                }
            }, toggler);

        }
    });

    onFind(selectors.goBack, function(button) {
        if(isTouchDevice()) {
            on('click', function (evt) {
                let item = evt.target;
                let closestOverlay = closest(selectors.overlay, item);
                removeClass('show-menu', closestOverlay);
                let closestToggler = closest(selectors.toggler, item);
                removeClass('is-open', closestToggler);
            }, button);
        }
    });

    onFind(selectors.closeMobileNav, function(link) {
        if(isTouchDevice()) {
            on('click', function (evt) {
                removeClass('is-open', find(selectors.mainToggler));
                removeClass('show-menu', find(selectors.mainOverlay));
                removeClass('nav-is-open', find('body'));
            }, link);
        }
    });

}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}
function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}
