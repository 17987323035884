
import {addClass, removeClass, hasClass, find, findIn, on, trigger, closest} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import axios from "axios";
import Modal from 'bootstrap/js/dist/modal';


let selectors = {
    base: '.js-trigger-open-sidebar',
    triggerBtn: '.js-trigger-open-sidebar__trigger-btn',
    sidebarBtn: '.js-trigger-open-sidebar__sidebar-btn',
};
export function init () {
    onFind('.js-trigger-open-sidebar', function(element) {
        let triggerBtn = findIn(selectors.triggerBtn, element);
        let sidebarBtn = find(selectors.sidebarBtn);
        let form = closest('.js-ajax-form', triggerBtn);
        on('fetched.ajax-form', (evt) => {
            if(evt.detail.showSidebar){
                let cartButton = document.querySelector('.js-trigger-open-sidebar__sidebar-btn');
                trigger('click', cartButton);
            }
        }, form);
    });
}