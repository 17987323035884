import {on, find, findAllIn, addClass, removeClass, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-header-banner', function (sidebar) {
        let header = find('.js-header-banner__header');
        let headerHeight = header.offsetHeight;
        let sidebarTopValue = sidebar.offsetTop;
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (sidebarTopValue !== headerHeight) {
            sidebar.style.top = headerHeight + 'px';

            if(!sidebar.dataset.notSetHeight){
                if (isSafari && (window.innerHeight > 1000)){
                    sidebar.style.height = 'calc(90dvh - ' + headerHeight + 'px)';
                }else{
                    sidebar.style.height = 'calc(100dvh - ' + headerHeight + 'px)';
                }

                window.addEventListener('resize', function (event) {
                    if (isSafari && (window.innerHeight > 1000)) {
                        sidebar.style.height = 'calc(90dvh - ' + headerHeight + 'px)';
                    } else {
                        sidebar.style.height = 'calc(100dvh - ' + headerHeight + 'px)';
                    }
                }, true);
            }
        }
    });
}
