"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {initInScope,onFind} from "@elements/init-modules-in-scope";
import {findIn, find, findAllIn, removeClass} from '@elements/dom-utils';

const defaultOptions = {
    viewportOffset: 300,
    anchorScrollingOffset: 50
};

const defaultSelectors = {
    base: '.js-render-template',
    template: '.js-render-template__template'
};

export function init (options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createRenderTemplate(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createRenderTemplate( baseElement, options, selectors){
    onEnterViewPort(baseElement, function (renderItem) {
        renderTemplate(renderItem, options, selectors);
    }, { offset: options.viewportOffset });

    initialAnchorScrolling(options, selectors);
}

export function renderTemplate(renderItem, options, selectors) {
    let template = findIn(selectors.template, renderItem);

    if(template) {
        renderItem.innerHTML = template.innerHTML;

        //class will be removed after template rendering
        removeClass(selectors.base.replace('.', ''), renderItem);
        template.remove();

        initInScope(renderItem);
    }
}

export function renderAllTemplates(options, selectors) {
    let renderItems = findAllIn(selectors.base, document.body);
    renderItems.map(item => {
        if(!getPrefixedDataSet('render-template', item).skipRenderAll) {
            renderTemplate(item, options, selectors);
        }
    });
}

export function initialAnchorScrolling(options, selectors) {
    if (location.hash) {
        try {
            renderAllTemplates(options, selectors);

            let target = find(location.hash);
            if (target && target.length) {
                window.scrollTo(0, target.offset().top - options.anchorScrollingOffset);
            }
        } catch (e) {
            console.warn("render-template-hash error:", e);
        }
    }
}