import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, closest} from "@elements/dom-utils";
import Alert from 'bootstrap/js/dist/alert';

export function init() {
    onFind('.alert-dismissible', function (element) {
        let alertInit = new Alert(element);
        let close = findIn('[data-dismiss="alert"]', element);
        on('click', (evt) => {
            alertInit.close();
        }, close);
    });
}



