
import {on, find, findIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";import Toast from 'bootstrap/js/dist/toast';

export function init () {
    onFind('.js-newsletter-box', function (baseElement) {
        const toastInstance = new Toast(baseElement);
        if (!sessionStorage.getItem('newsletterBox')) {
            setTimeout(function(){
                toastInstance.show();
            }, 30000);
        }else{
            toastInstance.hide();
        }
        let closeBtn = findIn('.js-newsletter-box__close', baseElement);
        on('click', (e) => {
            sessionStorage.setItem('newsletterBox', true);
        }, closeBtn);
    });
}

