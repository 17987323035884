import {on, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind(".js-product-grid-tracking", function (form) {
        on('submit.ajax-form', function (evt){
            let checkedFilter = findAllIn('input[type="checkbox"]:checked',form);
            checkedFilter.forEach(function (checkbox) {
                window.dataLayer = window['dataLayer'] || [];
                let dataLayerData = {
                    'event': 'filter_select',
                    'filter_category': checkbox.dataset.productGridTrackingCategory,
                    'filter_value': checkbox.dataset.productGridTrackingValue
                };
                window.dataLayer.push(dataLayerData);
            });
        }, form);
    });
}
