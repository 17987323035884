import {showNotification} from '@elements/alert-notification';

export function throwAlertMessage(type, title, errorMessage, closable) {
    if (process.env.NODE_ENV === 'development') {
        showNotification({
            type: type,
            title: title,
            content: errorMessage,
            closable: closable
        });
    }
}