import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-language-switch', function () {
        onFind('.js-language-switch__button', function (button) {
            on('click', function () {
                let date = new Date();
                date.setFullYear(date.getFullYear() + 1);
                let expires = "; expires=" + date.toUTCString();
                let lang = button.dataset.lang.toLowerCase().replace('_', '-');
                document.cookie = "language=" + lang + "; path=/; expires=" + expires;
            }, button);
        });
    });
}
